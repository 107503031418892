import React, { useContext, useEffect } from 'react'
import LocaleContext from '../../context/LocaleContext'
import PageContext from '../../context/PageContext'
import { Locale, localisePath } from '../../domain/locale'
import { CART_PATH } from '../../domain/path'
import Layout from '../../components/layout/Layout'
import Seo from '../../components/layout/Seo'
import Cart from '../../components/cart/Cart'

function CartPage() {
    const { setLocale } = useContext(LocaleContext)

    useEffect(() => {
        setLocale(Locale.AU)
    }, [])

    return (
        <PageContext.Provider
            value={{
                basepath: CART_PATH,
                locale: Locale.AU,
                localised: true,
            }}
        >
            <Layout>
                <Seo
                    title="Cart"
                    titleCompany="Telobag AU"
                    relativeUrl={localisePath(CART_PATH, Locale.AU)}
                />

                <div className="mb-6">
                    <h1 className="font-bold text-3xl md:text-5xl mb-3 text-primary">
                        Cart
                    </h1>

                    <hr className="border-b border-gainsboro w-10" />
                </div>

                <Cart message="Delivery may take a longer time than usual due to COVID. The estimated time of arrival is around 2 - 7 business days after dispatch." />
            </Layout>
        </PageContext.Provider>
    )
}

export default CartPage
